




























import CreateSeverityScale from './CreateSeverityScale.vue';
import SeverityScaleTable from './SeverityScaleTable.vue';
import { Booking, Patient } from '@/models';
import { PropType, defineComponent, ref } from '@/plugins/composition';

export default defineComponent({
  components: { CreateSeverityScale, SeverityScaleTable },
  name: 'SeverityScaleSection',
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
    booking: {
      type: Object as PropType<Booking>,
    },
  },
  setup() {
    const refreshRequired = ref(false);
    const creating = ref(false);

    return {
      refreshRequired,
      creating,
    };
  },
});
