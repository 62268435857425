





















import { Patient } from '@/models';
import { PropType, defineComponent, onMounted, ref, watch } from '@/plugins/composition';
import { SeverityScale } from '@/models/SeverityScale';

export default defineComponent({
  name: 'SeverityScaleTable',
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
    refreshRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const scales = ref<SeverityScale[]>([]);
    const headers = [
      {
        text: 'Date',
        value: 'data.createdAt',
      },
      {
        text: 'STGS',
        value: 'data.stgs',
      },
      {
        text: 'CPFS',
        value: 'data.cpfs',
      },
      {
        text: 'RNAROM',
        value: 'data.rnarom',
      },
      {
        text: 'result',
        value: 'data.result',
      },
      {
        text: 'Body Part',
        value: 'data.bodyPart.name',
      },
      {
        text: 'Side',
        value: 'data.side',
      },
      {
        text: 'Notes',
        value: 'data.notes',
      },
    ];

    onMounted(async () => {
      scales.value = await props.patient.severityScales().getAll();
    });

    watch(
      () => props.refreshRequired,
      async (val: boolean) => {
        if (val) {
          scales.value = await props.patient.severityScales().getAll();
          emit('refreshed');
        }
      }
    );

    return {
      scales,
      headers,
    };
  },
});
