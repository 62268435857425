









































import BookingNotesInput from '../../components/BookingNotesInput.vue';
import BookingStatusInput from './components/BookingStatusInput.vue';
import PatientBookingsTable from '../../../patients/components/PatientBookingsTable.vue';
import ProfileTile from '../../../shared/components/ProfileTile.vue';
import SeverityScaleSection from './components/SeverityScaleSection.vue';
import SidebarLayout from '../../../shared/layouts/SidebarLayout.vue';
import ViewBookingSidebar from './components/ViewBookingSidebar.vue';
import { Booking } from '@/models';
import { BookingNote } from '@/models/BookingNote';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import { useRouter } from '@/router';

export default defineComponent({
  components: {
    SidebarLayout,
    ViewBookingSidebar,
    ProfileTile,
    BookingNotesInput,
    PatientBookingsTable,
    BookingStatusInput,
    SeverityScaleSection,
  },
  name: 'ViewBookingPage',

  setup() {
    const booking = ref<Booking | null>();
    const notes = ref<BookingNote[]>([]);
    const activeNote = ref<BookingNote>();
    const { route } = useRouter();
    const loading = ref(false);
    const tab = ref(0);

    const fetchBooking = async () => {
      loading.value = true;
      booking.value = await Booking.with('bookingReasons').find(route.value.params.uuid);
      notes.value = await booking.value.notes().get();
      activeNote.value = notes.value[0];
      loading.value = false;
    };

    onMounted(async () => await fetchBooking());

    const handleInput = () => {
      //
    };

    return {
      booking,
      fetchBooking,
      loading,
      handleInput,
      notes,
      activeNote,
      route,
      tab,
    };
  },
});
