





















































































import { Booking, BookingReason, Patient } from '@/models';
import { PropType, computed, defineComponent, onMounted, ref } from '@/plugins/composition';
import { rules } from '@/domains/practitioner/modules/assessments/composables/validations';

export default defineComponent({
  name: 'CreateSeverityScale',
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
    booking: {
      type: Object as PropType<Booking>,
    },
  },
  setup(props, { emit }) {
    const valid = ref(false);
    const bodyPart = ref<BookingReason | null>(null);
    const stgs = ref<string | null>(null);
    const cpfs = ref<string | null>(null);
    const rnarom = ref<string | null>(null);
    const bodyParts = ref<BookingReason[]>([]);
    const sides = ['left', 'right', 'N/A'];
    const side = ref(null);
    const notes = ref('');

    const result = computed(() => {
      if (!stgs.value || !cpfs.value || !rnarom.value) {
        return 0;
      }

      return parseInt(stgs.value) + parseInt(cpfs.value) * 10 + parseInt(rnarom.value);
    });

    onMounted(async () => {
      bodyParts.value = await BookingReason.where('category', 'physical').getAll();
    });

    const submit = async () => {
      await props.patient.severityScales().create({
        stgs: stgs.value,
        cpfs: cpfs.value,
        rnarom: rnarom.value,
        bodyPart: bodyPart.value?.uuid,
        booking: props.booking?.uuid,
        side: side.value,
        notes: notes.value,
      });
      emit('input');
    };

    return {
      valid,
      stgs,
      cpfs,
      rnarom,
      bodyPart,
      bodyParts,
      sides,
      side,
      submit,
      result,
      rules,
      notes,
    };
  },
});
