

















import TextEditor from '@/components/inputs/TextEditor.vue';
import { Booking } from '@/models';
import { BookingNote } from '@/models/BookingNote';
import { PropType, computed, defineComponent, ref, watch } from '@/plugins/composition';
import { debouncedSearch } from '@/utils/inputSearch';

export default defineComponent({
  components: { TextEditor },
  name: 'BookingNotesInput',
  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },

  setup(props) {
    const saving = ref(false);
    const allNotes = ref<BookingNote[]>([]);
    const activeNote = ref<BookingNote>(new BookingNote());

    const fetchNotes = async () => {
      allNotes.value = await props.booking.notes().getAll();
      activeNote.value = allNotes.value[0];
    };

    const notes = computed({
      get: () => activeNote.value?.data.notes ?? '',
      set: (value: string) => {
        saving.value = true;
        activeNote.value.tempData.notes = value;
        saveNotes();
      },
    });

    const saveBookingNotes = async () => {
      saving.value = true;
      activeNote.value?.update();
      saving.value = false;
    };

    const saveNotes = debouncedSearch(() => saveBookingNotes());

    watch(
      () => {
        props.booking;
      },
      () => fetchNotes(),
      { immediate: true }
    );

    return {
      notes,
      saving,
      allNotes,
    };
  },
});
