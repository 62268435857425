






import { Booking } from '@/models';
import { PropType, computed, defineComponent } from '@/plugins/composition';

export default defineComponent({
  name: 'CareTypeInput',
  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },
  setup(props) {
    const types = ['reactive', 'proactive'];

    const careType = computed({
      get: () => props.booking.data.careType,
      set: (value: 'proactive' | 'reactive' | null | undefined) => {
        console.log({
          careType: value,
        });

        props.booking.update({
          careType: value ? value : null,
        });
      },
    });

    return {
      types,
      careType,
    };
  },
});
