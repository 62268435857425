























import { BookingReason } from '@/models';
import { PropType, computed, defineComponent, onMounted, ref } from '@/plugins/composition';

export default defineComponent({
  name: 'BookingReasonsInput',
  props: {
    value: {
      type: Array as PropType<BookingReason[] | null>,
      required: false,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const items = ref<BookingReason[]>(props.value ? props.value : []);

    const fetchBookingReasons = async () => {
      items.value = await BookingReason.getAll();
    };

    const reasons = computed({
      get: () => props.value,
      set: (items: BookingReason[] | null) => emit('input', items),
    });

    const bookingItems = computed(() => {
      const groups: { uuid?: string; name: string; children: BookingReason[] }[] = [];
      items.value?.forEach((bookingReason: BookingReason) => {
        const item = groups.find((item) => item.name == bookingReason.data.category);
        if (item) {
          item.children.push(bookingReason);
        } else {
          groups.push({
            name: bookingReason.data.category,
            children: [bookingReason],
            uuid: (Math.random() + 1).toString(36).substring(7).toString(),
          });
        }
      });

      return groups;
    });

    onMounted(() => fetchBookingReasons());

    return {
      fetchBookingReasons,
      bookingItems,
      reasons,
    };
  },
});
