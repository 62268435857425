var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.scales,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("top")]},proxy:true},{key:"item.data.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data.createdAt))+" ")]}},{key:"item.data.notes",fn:function(ref){
var item = ref.item;
return [(item.data.notes)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-note ")])]}}],null,true)},[_c('div',{domProps:{"innerHTML":_vm._s(item.data.notes)}})]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }