






import { Booking } from '@/models';
import { PropType, computed, defineComponent } from '@/plugins/composition';

export default defineComponent({
  name: 'BookingDeliveryTypeInput',
  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },
  setup(props) {
    const types = ['phone', 'in person'];

    const deliveryType = computed({
      get: () => props.booking.data.bookingDeliveryType,
      set: (value: 'in person' | 'phone' | null | undefined) => {
        props.booking.update({
          bookingDeliveryType: value ? value : null,
        });
      },
    });

    return {
      types,
      deliveryType,
    };
  },
});
