































































































import BookingDeliveryTypeInput from './BookingDeliveryTypeInput.vue';
import BookingReasonsInput from './BookingReasonsInput.vue';
import CareTypeInput from './CareTypeInput.vue';
import SmsInteractionsDialog from '@/components/interactions/sms-interaction/SmsInteractionsDialog.vue';
import { Booking, BookingReason, Patient } from '@/models';
import { PropType, computed, defineComponent, ref } from '@/plugins/composition';
import { confirmation } from '@/domains/shared/components/confirm-dialog/confirm';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router';

export default defineComponent({
  components: { BookingReasonsInput, SmsInteractionsDialog, CareTypeInput, BookingDeliveryTypeInput },
  name: 'ViewBookingSidebar',
  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },

  setup(props) {
    const booking = props.booking; // TODO - remove this clone somehow

    const patient = booking.patient as Patient;
    const showSms = ref(false);

    const { router } = useRouter();

    const reasons = computed({
      get: () => [...booking.bookingReasons],
      set: (reasons: BookingReason[]) => {
        booking.bookingReasons = [...reasons];
        booking.updateBookingReasons();
      },
    });

    const destroy = async (): Promise<void> => {
      const confirm = await confirmation('Are you sure you want to delete this booking?', {
        confirmText: 'Delete',
        cancelText: 'Cancel',
        confirmType: 'warn',
      });

      if (confirm) {
        await props.booking.delete();

        router.push({ name: routeNames.practitioner.bookings.my.day });
      }
    };

    return {
      destroy,
      patient,
      reasons,
      showSms,
    };
  },
});
