

















import { Booking, BookingStatuses } from '@/models';
import { PropType, defineComponent, onMounted, ref } from '@/plugins/composition';

export default defineComponent({
  name: 'BookingStatusInput',

  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },

  setup(props) {
    const statuses = ref<BookingStatuses[]>([]);
    const status = ref(0);

    const fetchStatuses = async () => {
      statuses.value = await Booking.getAllowedStatuses();
      status.value = statuses.value.indexOf(props.booking.data.status);
    };

    const updateStatus = async () => {
      await props.booking.update({
        status: statuses.value[status.value],
      });
    };

    onMounted(() => fetchStatuses());

    return {
      statuses,
      status,
      updateStatus,
    };
  },
});
